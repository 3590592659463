import { IcebergAvatar } from "@tradesolution/iceberg-ui-react";
import { Col, Row } from "react-bootstrap";
import Confetti from '../Confetti';
import { MatchStatusDto } from "../../services/Types";
import Flip from '../Flip';
import { Button } from 'react-bootstrap'
import IcebergIcon from '../IcebergIcon';
import football from '../../football.png';
import trophy from '../../trophy.png';

interface Props {
    status: MatchStatusDto;
    onScore: (team: "red" | "blue") => void;
    restartMatchWithSamePlayers: () => void;
}

const GameStatusDisplay = (props: Props) => {

    const red = props.status.teams.find(o => o.name === "red");
    const blue = props.status.teams.find(o => o.name === "blue");

    const reload = () => {
        window.location.reload();
    }

    return (
        <>
            {props.status.switchPlaces &&
                <Row>
                    <Col>
                        <Flip />
                    </Col>
                </Row>}
            {props.status.finished && <Row>
                <Col>
                    <Button className="App-link" onClick={reload}><IcebergIcon icon="synchronize" /> Start nytt spill</Button>
                </Col>
                <Col>
                    <Button className="App-link" onClick={props.restartMatchWithSamePlayers}><IcebergIcon icon="synchronize" /> Start nytt spill med samme spillere</Button>
                </Col>
            </Row>}
            <Row>

                <Col style={{ color: "white", backgroundColor: 'red', height: '750px' }} onClick={() => props.onScore("red")}>
                    <h3>Rødt lag</h3>
                    <Row>
                        <Col>
                            <div>{red?.members[0].name}</div>
                            <IcebergAvatar firstName={red?.members[0].name || ""} imgUrl={red?.members[0].imageUrl || ""} />
                        </Col>
                        <Col>
                            <div>{red?.members[1].name}</div>
                            <IcebergAvatar firstName={red?.members[1].name || ""} imgUrl={red?.members[1].imageUrl || ""} />
                        </Col>
                    </Row>
                    <div style={{ fontSize: "30px", textAlign: "center", color: "white" }}>
                        {red?.goals}
                    </div>
                    {props.status.finished && red && blue && red.goals > blue.goals && <div style={{ fontSize: '3rem' }}>Rødt lag vant<Confetti /></div>}
                    <img src={football} style={{cursor: 'pointer', width: '20vw'}} />
                    {
                        props.status.finished && red && blue && red.goals > blue.goals && <img src={trophy} style={{cursor: 'pointer', width: '20vw'}} />
                    }
                </Col>
                <Col style={{ color: "white", backgroundColor: 'blue' }} onClick={() => props.onScore("blue")}>
                    <h3>Blått lag</h3>
                    <Row>
                        <Col>
                            <div>{blue?.members[0].name}</div>
                            <IcebergAvatar firstName={blue?.members[0].name || ""} imgUrl={blue?.members[0].imageUrl || ""} />
                        </Col>
                        <Col>
                            <div>{blue?.members[1].name}</div>
                            <IcebergAvatar firstName={blue?.members[1].name || ""} imgUrl={blue?.members[1].imageUrl || ""} />
                        </Col>
                    </Row>
                    <div style={{ fontSize: "30px", textAlign: "center", color: "white" }}>
                        {blue?.goals}
                    </div>
                    {/* {blue && red && blue.goals > red.goals && <Confetti />} */}
                    {props.status.finished && red && blue && blue.goals > red.goals && <div style={{ fontSize: '3rem' }}>Blått lag vant<Confetti /></div>}
                    <img src={football} style={{cursor: 'pointer', width: '20vw'}} />
                    {
                        props.status.finished && red && blue && blue.goals > red.goals && <img src={trophy} style={{cursor: 'pointer', width: '20vw'}} />
                    }
                    
                </Col>
            </Row >
        </>);
};

export default GameStatusDisplay;