import { useEffect, useRef } from 'react';
import {
    icebergIconsCopyToClipboard,
    icebergIconsEdit,
    icebergIconsSynchronize,
    icebergIconsEllipsis,
    icebergIconsEllipsisHorizontal,
    icebergIconsOrg,
    icebergIconsClose,
    icebergIconsQuestion,
    icebergIconsExclamationMark,
    icebergIconsCheck,
    icebergIconsExport,
    icebergIconsChevronDown,
    icebergIconsChevronUp,
    icebergIconsImport,
    icebergIconsSearch,
    icebergIconsPlus,
    icebergIconsTrash,
    icebergIconsEyeOpen,
    icebergIconsInfo,
    icebergIconsTable,
    icebergIconsList,
    icebergIconsFilter,
    icebergIconsRedo,
    icebergIconsArrowsUpDown,
    icebergIconsArrowDown,
    icebergIconsArrowUp,
    icebergIconsArrowLeft,
    icebergIconsArrowRight,
    icebergIconsDisk,
    icebergIconsAlert,
    icebergIconsClock
} from '@tradesolution/iceberg-icons';
import { IconName } from './types';

interface Props {
    icon: IconName;	
    color?: string;
    backgroundColor?: string;
    rounded?: boolean;
    title?: string;
    size?: 'xs' | 'sm' | 'lg' | '2x' | string;
    className?: string;
    cursor?: string;
    bold?: boolean;
    thick?: boolean;
    onClick?: () => void;
}

const IcebergIcon = (props: Props) => {
    const containerRef = useRef<HTMLSpanElement>(null);

    const getSvgString = (icon: string) => {
        let svgString = ''
        switch (icon) {
            case 'copy':
                svgString = icebergIconsCopyToClipboard.data;
                break;
            case 'edit':
                svgString = icebergIconsEdit.data;
                break;
            case 'synchronize':
                svgString = icebergIconsSynchronize.data;
                break;
            case 'ellipsis':
                svgString = icebergIconsEllipsis.data;
                break;
            case 'ellipsis-h':
                svgString = icebergIconsEllipsisHorizontal.data;
                break;
            case 'org':
                svgString = icebergIconsOrg.data;
                break;
            case 'close':
                svgString = icebergIconsClose.data;
                break;
            case 'save':
                svgString = icebergIconsDisk.data;
                break;
            case 'question':
                svgString = icebergIconsQuestion.data;
                break;
            case 'exclamation':
                svgString = icebergIconsExclamationMark.data;
                break;
            case 'check':
                svgString = icebergIconsCheck.data;
                break;
            case 'export':
                svgString = icebergIconsExport.data;
                break;
            case 'chevron-down':
                svgString = icebergIconsChevronDown.data;
                break;
            case 'chevron-up':
                svgString = icebergIconsChevronUp.data;
                break;
            case 'alert':
                svgString = icebergIconsAlert.data;
                break;
            case 'import':
                svgString = icebergIconsImport.data;
                break;
            case 'search':
                svgString = icebergIconsSearch.data;
                break;
            case 'plus':
                svgString = icebergIconsPlus.data;
                break;
            case 'trash':
                svgString = icebergIconsTrash.data;
                break;
            case 'eye':
                svgString = icebergIconsEyeOpen.data;
                break;
            case 'info':
                svgString = icebergIconsInfo.data;
                break;
            case 'table':
                svgString = icebergIconsTable.data;
                break;
            case 'list':
                svgString = icebergIconsList.data;
                break;
            case 'filter':
                svgString = icebergIconsFilter.data;
                break;
            case 'redo':
                svgString = icebergIconsRedo.data;
                break;
            case 'arrow-up-down':
                svgString = icebergIconsArrowsUpDown.data;
                break;
            case 'arrow-down':
                svgString = icebergIconsArrowDown.data;
                break;
            case 'arrow-up':
                svgString = icebergIconsArrowUp.data;
                break;
            case 'arrow-left':
                svgString = icebergIconsArrowLeft.data;
                break;
            case 'arrow-right':
                svgString = icebergIconsArrowRight.data;
                break;
            case 'clock':
                svgString = icebergIconsClock.data;
                break;
        }

        if(svgString.length > 0){
            if(props.bold){
                svgString = svgString.replaceAll(/(?<=<path\b[^<>]*)\s*\bfill=(["'](?!none)).*?\1/g, ` fill="${props.color || 'currentColor'}" stroke="${props.color || 'currentColor'}" stroke-width="1px"`);
            } else if(props.thick) {
                svgString = svgString.replaceAll(/(?<=<path\b[^<>]*)\s*\bfill=(["'](?!none)).*?\1/g, ` fill="${props.color || 'currentColor'}" stroke="${props.color || 'currentColor'}" stroke-width="1.4px"`);
            } else {
                svgString = svgString.replaceAll(/(?<=<path\b[^<>]*)\s*\bfill=(["'](?!none)).*?\1/g, ` fill="${props.color || 'currentColor'}"`); 
            }
            // svgString = svgString.replaceAll(/(?<=<path\b[^<>]*)\s*\bclip-rule=(["'](?!none)).*?\1/g, "");
            // svgString = svgString.replaceAll(/(?<=<path\b[^<>]*)\s*\bfill-rule=(["'](?!none)).*?\1/g, "");
        }

        return svgString;
    }

    let size: string | number = 20;
    if(props.size === 'sm'){
        size = 14;
    } else if(props.size === 'lg'){
        size = 24;
    } else if(props.size === '2x'){
        size = 28;
    } else if(props.size){
        size = props.size;
    }

    useEffect(() => {
        if(containerRef.current){
            containerRef.current.innerHTML = getSvgString(props.icon);
        }
    }, [props.icon])

    return (
        // <img className={Style.img} height={size} width={size} style={{ borderRadius: props.rounded ? size : 0, backgroundColor: props.backgroundColor ? props.backgroundColor : 'transparent' }} 
        //      src={`data:image/svg+xml;utf8,${encodeURIComponent(getSvgString(props.icon))}`} />
        <span style={{
                borderRadius: props.rounded ? size : 0, 
                cursor: props.cursor || '',
                backgroundColor: props.backgroundColor || 'transparent',
                height: size,
                width: size,
                lineHeight: 0,
                display: 'inline-block'
             }} 
             title={props.title}
             ref={containerRef}
             //height={size} width={size}
             className={props.className}
             onClick={props.onClick}
             //src={`data:image/svg+xml;utf8,${encodeURIComponent(getSvgString(props.icon))}`}
             />
    );
};

export default IcebergIcon;