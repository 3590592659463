import { MatchStatusDto, Player, PlayerRankRow, StartMatchRequest, Top10Row } from "./Types";

const baseUrl = 'https://whatthefuzzapi.azurewebsites.net';

export default {
    getPlayers: async (): Promise<Player[]> => {
        const response = await fetch(baseUrl + '/Game/Players');
        return await response.json();
    },
    startMatch: async (request: StartMatchRequest): Promise<MatchStatusDto> => {
        const mappedRequest = {
            teams: [
                {
                    name: "red",
                    members: [
                        request.player1, request.player2
                    ]
                },
                {
                    name: "blue",
                    members: [
                        request.player3, request.player4
                    ]
                }
            ]
        };
        const response = await fetch(baseUrl + '/game/match', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mappedRequest)
        });
        return await response.json();
    },
    goalScored: async (matchId: string, team: "red" | "blue"): Promise<MatchStatusDto> => {
        const response = await fetch(`${baseUrl}/game/match/${matchId}/score`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                teamName: team
            })
        });
        return await response.json();
    },
    top10: async (): Promise<Top10Row[]> => {
        const response = await fetch(`${baseUrl}/game/GamesTop10Results`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    },
    wallOfShame: async (): Promise<Top10Row[]> => {
        const response = await fetch(`${baseUrl}/game/wallofshame`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    },
    playerRank: async (): Promise<PlayerRankRow[]> => {
        const response = await fetch(`${baseUrl}/game/playerrank`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    },
};