import { ConfettiCanvas } from "react-raining-confetti";

const Confetti = () => {
  return (
    <div>
        {/* <h1 style={{ top: '50%', left: "50%", position: 'absolute' }}> You win!! </h1> */}
        <ConfettiCanvas active={true} fadingMode="LIGHT" stopAfterMs={5000} />
    </div>
  );
}

export default Confetti;