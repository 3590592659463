import { IcebergAvatar } from "@tradesolution/iceberg-ui-react";
import { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { Player, StartMatchRequest } from "../../services/Types";

interface Props {
    onStart: (request: StartMatchRequest) => void;
    players: Player[];
}

const PlayerAvatar = (props: { player?: Player, nr: number }) => {
    if (props.player)
        return <IcebergAvatar firstName={props.player.name} imgUrl={props.player.imageUrl || ""} />
    return <Form.Label style={{ color: 'white' }}>Spiller {props.nr}</Form.Label>
};

const TeamSelector = (props: Props) => {

    const [error, setError] = useState<string | undefined>();

    const hasSelectedDuplicatePlayers = () => {
        const players = [player1, player2, player3, player4];
        return players.length > new Set(players).size;
    };

    const startMatch = () => {
        if (hasSelectedDuplicatePlayers()) {
            setError("Kan'ke velge samme spiller to ganger");
            return;
        }
        if (player1 && player2 && player3 && player4) {
            props.onStart({
                player1: player1,
                player2: player2,
                player3: player3,
                player4: player4,
            });
            setError(undefined);
        } else {
            setError("Har vi 4 spillere?");
        }
    };

    const [player1, setPlayer1] = useState<string>();
    const [player2, setPlayer2] = useState<string>();
    const [player3, setPlayer3] = useState<string>();
    const [player4, setPlayer4] = useState<string>();

    return (
        <>
            {error && <Alert variant="danger">{error}</Alert>}
            <Row style={{ color: 'white', backgroundColor: '#282c34' }} >
                <Col>
                    <h3 style={{ color: 'white' }}>Rødt lag</h3>
                    <Form.Group style={{ marginBottom: "1rem" }}>
                        <PlayerAvatar player={props.players.find(p => p.id === player1)} nr={1} />
                        <Form.Select value={player1} onChange={e => setPlayer1(e.target.value)}>
                            <option value="">Velg</option>
                            {props.players.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <PlayerAvatar player={props.players.find(p => p.id === player2)} nr={2} />
                        <Form.Select value={player2} onChange={e => setPlayer2(e.target.value)}>
                            <option value="">Velg</option>
                            {props.players.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col>
                    <h3 style={{ color: 'white' }}>Blått lag</h3>
                    <Form.Group style={{ marginBottom: "1rem" }}>
                        <PlayerAvatar player={props.players.find(p => p.id === player3)} nr={1} />
                        <Form.Select value={player3} onChange={e => setPlayer3(e.target.value)}>
                            <option value="">Velg</option>
                            {props.players.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <PlayerAvatar player={props.players.find(p => p.id === player4)} nr={2} />
                        <Form.Select value={player4} onChange={e => setPlayer4(e.target.value)}>
                            <option value="">Velg</option>
                            {props.players.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row style={{ margin: "2rem 0" }}>
                <Col>
                    <Button onClick={startMatch}>Nu kjør vi!</Button>
                </Col>
            </Row>
        </>
    );
};

export default TeamSelector;