import { Table } from "react-bootstrap";
import { format, intervalToDuration } from "date-fns";
import { Top10Row } from "../../services/Types";

interface Props {
    data: Top10Row[]
}

const Last10Table = (props: Props) => {
    const formatIntervalToText = (duration: Duration) => {
        return `${duration.minutes} min, ${duration.seconds} sek`;
    }

    return (
        <div className="table-responsive">
            <Table style={{ color: "wheat" }} >
                <thead>
                    <tr>
                        <th>Dato</th>
                        <th>Rødt lag</th>
                        <th>Resultat</th>
                        <th>Blått lag</th>
                        <th>Spillengde</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(d => <tr key={d.gameId}>
                        <td>{format(new Date(d.dateAndTime), 'dd.MM.yy')}</td>
                        <td>{d.redPlayers}</td>
                        <td>{d.result}</td>
                        <td>{d.bluePlayers}</td>
                        <td>{formatIntervalToText(intervalToDuration({ start: 0, end: d.secoundsPlayed * 1000 }))}</td>
                    </tr>)}
                </tbody>
            </Table>
        </div>
    );
};

export default Last10Table;
