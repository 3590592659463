import React, { useEffect, useState } from 'react';
import logo from './logo.png';
import { IcebergAvatar } from '@tradesolution/iceberg-ui-react';
import TeamSelector from './components/TeamSelector';
import Api from './services/Api';
import { MatchStatusDto, Player, PlayerRankRow, StartMatchRequest, Top10Row } from './services/Types';
import GoalClicker from './components/GoalClicker';
import GameStatusDisplay from './components/GameStatusDisplay';
import { Button, Tab, Table, Tabs } from 'react-bootstrap'
import './App.css';
import Last10Table from './components/Last10Table';

function App() {

  const [players, setPlayers] = useState<Player[]>([]);

  useEffect(() => {
    Api.getPlayers().then(setPlayers);
  }, []);

  const [gamestate, setGamestate] = useState<string | undefined>();
  const [showHeader, setShowHeader] = useState<boolean>(true);

  const startGame = () => {
    setGamestate('select-team');
    setShowHeader(false);
  };

  const [matchStatus, setMatchStatus] = useState<MatchStatusDto | undefined>();

  const handleMatchStart = async (request: StartMatchRequest): Promise<MatchStatusDto> => {
    const response = await Api.startMatch(request);
    setGamestate('started');
    setMatchStatus(response);
    return response;
  };

  const restartMatchWithSamePlayers = async () => {
    if (matchStatus) {
      const request: StartMatchRequest = {
        player1: matchStatus?.teams[0].members[0].id,
        player2: matchStatus?.teams[0].members[1].id,
        player3: matchStatus?.teams[1].members[0].id,
        player4: matchStatus?.teams[1].members[1].id,
      };
      const response = await Api.startMatch(request);
      setGamestate('started');
      setMatchStatus(response);
    }
  };

  const handleGoalScored = async (team: 'red' | 'blue') => {
    if (matchStatus && !matchStatus.finished) {
      const updatedStatus = await Api.goalScored(matchStatus.matchId, team);
      setMatchStatus(updatedStatus);
    }
  };

  const loadLast10 = async () => {
    const response = await Api.top10();
    setLast10(response);
  };

  const [last10, setLast10] = useState<Top10Row[]>([]);
  useEffect(() => {
    loadLast10();
  }, []);

  const loadWallOfShame = async () => {
    const response = await Api.wallOfShame();
    setWallOfShame(response);
  };

  const [wallOfShame, setWallOfShame] = useState<Top10Row[]>([]);
  useEffect(() => {
    loadWallOfShame();
  }, []);

  const loadPlayerRank = async () => {
    const response = await Api.playerRank();
    setPlayerRank(response);
  };

  const [playerRank, setPlayerRank] = useState<PlayerRankRow[]>([]);
  useEffect(() => {
    loadPlayerRank();
  }, []);

  return (
    <div className="App">
      <div className={showHeader ? 'App-header' : 'App-header-small'}>
        {
          !gamestate ? <img src={logo} className="App-logo" alt="logo" /> : <img src={logo} className="App-logo-small" alt="logo" />
        }

        {!gamestate && <Button className="App-link" style={{ width: '120px' }} onClick={startGame}>Start</Button>}

      </div>
      <div style={{ padding: "2rem", backgroundColor: '#282c34' }}>
        {gamestate === 'select-team' && <TeamSelector players={players} onStart={handleMatchStart} />}
        {gamestate === 'started' && (
          <>
            {matchStatus && <GameStatusDisplay restartMatchWithSamePlayers={restartMatchWithSamePlayers} status={matchStatus} onScore={handleGoalScored} />}
          </>)}
        <div style={{ marginTop: "1rem", color: "wheat" }}>
          <Tabs
            defaultActiveKey="siste10"
          >
            <Tab eventKey="siste10" title="Siste 10">
              <h3>10 siste spill</h3>
              <Last10Table data={last10} />
            </Tab>
            <Tab eventKey="wallOfShame" title="Wall of Shame">
              <h3>Wall of shame</h3>
              <Last10Table data={wallOfShame} />
            </Tab>
            <Tab eventKey="ranking" title="Ranking">
              <h3>Spiller ranking</h3>
              <Table style={{ color: "wheat" }}>
                <thead>
                  <tr>
                    <th>Navn</th>
                    <th>Antall lagmål</th>
                  </tr>
                </thead>
                <tbody>
                  {playerRank.map((p, i) => <tr key={p.playerId}>
                    <td>{p.name}</td>
                    <td>{p.sumGoals}</td>
                  </tr>)}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default App;
