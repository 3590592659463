import React from 'react'
import './index.css'

const Flip = () => {
  return (
    <div className="wrapper">
  <div className="card">
    <div className="front-side">
      <p>Players switch places</p>
    </div>
    <div className="back-side">
      <p>Players switch places</p>
    </div>
  </div>
</div>
  )
}
export default Flip
